<template>
  <v-container>
    <v-app-bar dark app color="primary" clipped-right dense>
      <v-app-bar-nav-icon>
        <v-icon>mdi-monitor</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Screen</v-toolbar-title>
      <v-spacer />

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'ScreenPreview' }"> Aktuell </v-tab>
          <v-tab :to="{ name: 'ScreenScreens' }"> Screens </v-tab>
          <v-tab :to="{ name: 'ScreenSettings' }"> Einstellungen </v-tab>
        </v-tabs>
        <v-btn
          :to="{ name: 'ScreenScreen', params: { id: 0 } }"
          absolute
          bottom
          right
          color="success"
          fab
          small
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <router-view :search="search" />
  </v-container>
</template>
<script>
export default {
  name: 'Screen',
  data() {
    return {
      loading: false,
      search: '',
    };
  },
};
</script>
